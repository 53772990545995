export default function About () {
    return (
        <div className="container-about">
            <span> <box className="todo"> TODO:</box> this</span>
            <br />
            <p color="red">
                /* <br />
                Here is a picture of my dog. Everybody loves dogs. <br /> <br />
                <img src="/assets/max.png" /> <br />
                */
            </p>
        </div>
    );
}